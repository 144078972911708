:root {
  --background-color: #f8f9fa;
  --text-color: #333;
  --font-family: 'Georgia', serif;
  --font-size-base: 1.2rem;
  --font-size-large: 1.3em;
  --font-size-hover: 1.5em;
  --primary-color: #6c757d;
  --highlight-color: #00ff99;
  --dark-background-color: #121212;
  --hover-scale: 1.1;
  --transition-speed: 0.3s;
  --border-radius: 10px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Global Styles */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: 1.3;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  transition: background-color var(--transition-speed), color var(--transition-speed);
}

section {
  max-width: 1200px;
  margin: 0 auto;
}

h2, h3 {
  font-size: var(--font-size-large);
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: var(--highlight-color);
}

ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

li {
  font-size: 1.1rem;
  color: var(--text-color);
  margin-bottom: 10px;
}

button, a {
  transition: all var(--transition-speed);
}

/* Navbar */
.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-nav .nav-item {
  margin: 0 10px;
}

.navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--highlight-color);
  color: #121212;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  font-weight: bold;
  transition: transform var(--transition-speed), background-color var(--transition-speed);
}

.logo-container:hover {
  transform: scale(var(--hover-scale));
  background-color: #00b389;
}

/* Dark Mode */
.dark-mode {
  --background-color: var(--dark-background-color);
  --text-color: #fff;
}

.dark-mode .navbar {
  background-color: var(--background-color);
}

.dark-mode .navbar-light .navbar-nav .nav-link {
  color: var(--text-color);
}

.dark-mode .navbar-light .navbar-toggler-icon {
  background-color: var(--text-color);
}

.dark-light-toggle {
  background: none;
  color: #0099cc;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.dark-light-toggle:hover {
  color: var(--highlight-color);
  transform: scale(var(--hover-scale));
}

/* Buttons */
.download-btn {
  display: inline-block;
  background-color: var(--background-color); /* matches background color from :root */
  color: var(--primary-color); /* using primary color for text */
  font-size: var(--font-size-base); /* base font size from :root */
  font-weight: bold;
  text-align: center;
  border-radius: var(--border-radius); /* rounded corners from :root */
  box-shadow: var(--box-shadow); /* box shadow from :root */
  transition: all var(--transition-speed); /* smooth transition */
  text-decoration: none;
}

.download-btn:hover {
  background-color: var(--highlight-color); /* highlight color for hover */
  color: #121212; /* dark text color when hovering */
  transform: scale(var(--hover-scale)); /* smooth hover scale effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* stronger shadow on hover */
}

.download-btn:active {
  background-color: #0077b3; /* darker blue on active */
  transform: translateY(1px); /* press down effect */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* shadow adjustment for active state */
}

.portfolio-btn {
  background-color: var(--highlight-color);
  color: #121212;
  border-radius: 25px;
}

.portfolio-btn:hover {
  background-color: #00b389;
}

.portfolio-btn:active {
  background-color: #009966;
}

/* Home Section */
.home-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background-color: var(--dark-background-color);
  color: #fff;
  flex-wrap: wrap;
  margin-top: 60px;
}

.left-content, .right-content {
  flex: 1;
  
}


.left-content {
  max-width: 50%;
  text-align: left;
}

.intro-text h1 {
  font-size: 2.5rem;
  color: #a3f7d8;
}

.subheading {
  font-size: 3rem;
  color: var(--highlight-color);
}

.subsubheading {
  font-size: 1.5rem;
  color: #fff;
  margin-top: 10px;
}

.description {
  font-size: 1.2rem;
  margin-top: 20px;
}

/* Profile Circle */
.profile-circle {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  border: 5px solid var(--highlight-color);
}

.profile-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Footer Stats */
.footer-stats {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  color: #a3f7d8;
}

.footer-stat-item {
  margin-top: 10px;
}

.iconstyle {
  font-size: 2.5rem;
  margin: 10px;
  transition: all var(--transition-speed);
}

.iconstyle:hover {
  transform: scale(1.2);
  color: var(--highlight-color);
}

/* Social Links */
.socials {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.socials li {
  margin: 0 15px;
}

.socials a {
  color: #fff;
  font-size: 2rem;
  transition: color var(--transition-speed);
}

.socials a:hover {
  color: var(--highlight-color);
}

/* About Section */
.about-section {
  text-align: left;
  padding: 100px 20px;
  margin: 0 auto;
}

.about-header h2 {
  font-size: 2rem;
  color: var(--highlight-color);
  margin-bottom: 10px;
}

.about-header span {
  font-size: 1.2rem;
}

.spacer-about {
  margin-bottom: 40px;
}

.about-me-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.aboutdetails {
  flex: 1 1 100%;
}

.aboutdetails p {
  margin-bottom: 20px;
}

.portfolio-btn-container {
  margin-top: 20px;
}

/* Skills Section */
.skills-section {
  padding: 60px 20px;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  color: var(--highlight-color);
}

.skills-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.skills-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 20px;
}

.skill-card {
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  padding: 20px;
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.skill-card h3 {
  font-size: var(--font-size-large);
  margin-bottom: 15px;
}

/* Projects*/
#projects {
  padding: 60px 20px;
  text-align: center;
  background-color: var(--background-color);
}

#projects h2 {
  font-size: 2rem;
  color: var(--highlight-color);
  margin-bottom: 40px;
  font-weight: bold;
  text-align: left;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  display: block;
  text-decoration: none;
  background-color: #fff;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed), box-shadow var(--transition-speed);
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: opacity var(--transition-speed);
}

.card img:hover {
  opacity: 0.9;
}

.card h3 {
  font-size: 1.5rem;
  color: var(--text-color);
  margin: 20px;
}

.card p {
  font-size: 1rem;
  color: #666;
  margin: 0 20px 20px;
}

/* Contact form */

/* Style for the contact form */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  font-family: var(--font-family);
}

.form-heading {
  font-size: var(--font-size-large);
  font-weight: bold;
  margin-bottom: 1rem;
  color: var(--highlight-color);
}

.form-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.form-label label {
  font-size: var(--font-size-base);
  color: var(--text-color);
  margin-bottom: 0.5rem;
}

.form-control {
  padding: 0.75rem;
  font-size: var(--font-size-base);
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  width: 100%;
  box-sizing: border-box;
  transition: border-color var(--transition-speed);
}

.form-control:focus {
  border-color: var(--highlight-color);
  outline: none;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: var(--font-size-base);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color var(--transition-speed), transform var(--transition-speed);
  margin-top: 1rem;
}

.btn-primary:hover {
  background-color: var(--highlight-color);
  transform: scale(var(--hover-scale));
}

.success-message {
  display: none;
  margin-top: 1rem;
  background-color: #28a745;
  color: white;
  padding: 1rem;
  border-radius: var(--border-radius);
  text-align: center;
}

.success-message.visible {
  display: block;
}
/* Mobile responsiveness */
@media (max-width: 768px) {
  .home-section {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 70px;
  }

  .about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100vh;
  }

  .skills-container {
    grid-template-columns: 1fr;
  }

  h2 {
    font-size: 2rem;
  }

  .card-container {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .skills-title {
    text-align: center;
  }
  .skill-card {
    text-align: center;
  }

  .card h3 {
    font-size: 1.3rem;
  }

  .card p {
    font-size: 0.9rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  #projects h2 {
    text-align: center;
  }
  .contact-form {
    padding: 1.5rem;
    max-width: 90%;
  }
  

  .form-heading {
    font-size: var(--font-size-base);
  }

  .form-control {
    font-size: 1rem; /* Slightly smaller input fields */
  }

  .btn-primary {
    padding: 0.5rem 1rem;
  }
  .footer-stats {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .intro-text h1 {
    font-size: 2rem;
  }

  .subheading {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .iconstyle {
    font-size: 2rem;
  }

  .footer-stat-item {
    font-size: 1rem;
  }


  .card-container {
    grid-template-columns: 1fr;
  }

  .card h3 {
    font-size: 1.2rem;
  }

  .card p {
    font-size: 0.8rem;
  }
}
